import React from "react";
import { Link } from 'gatsby';
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="utility-page-wrap">
      <div className="utility-page-content"><img src="../images/ico-404.png" width="80" srcSet="images/ico-404-p-500.png 500w, images/ico-404.png 558w" sizes="80px" alt="" className="pages-icon" />
        <h1>Page Not Found</h1>
        <p>The page you are looking for doesn&#x27;t exist or has been moved.</p>
        <div className="top-margin">
          <Link to="/"  className="line-wrapper-second w-inline-block">
            <div className="link-text-second" >Back to homepage</div>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
